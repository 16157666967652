<template>
  <div class="container">
    <a-alert type="info">
      <div slot="message">
        订单状态：<span style="color:red;">{{taskData.status | formateStatus()}}</span>
      </div>
    </a-alert>
    <div class="wrapper">
      <a-form :form="form" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-divider orientation="left">订单信息</a-divider>
        <a-form-item label="项目名称">
          <div>{{taskData.task_name}}</div>
        </a-form-item>
        <a-form-item label="开始时间">
          <div>{{taskData.start_time | formateDate(true)}}</div>
        </a-form-item>
        <a-form-item label="项目周期">
          <div>{{taskData.day}}天</div>
        </a-form-item>
        <a-form-item label="项目服务费">
          <div>¥{{taskData.task_money}}</div>
        </a-form-item>
        <a-form-item label="结算人数" v-if="taskData.bing && taskData.bing.length > 0">
          <div>{{taskData.bing.length}}</div>
        </a-form-item>
        <a-form-item label="项目类型">
          <div>{{taskData.g_name}}</div>
        </a-form-item>
        <a-form-item label="税收编码">
          <div>{{taskData.code}}</div>
        </a-form-item>
        <a-form-item label="税率">
          <div>{{taskData.tax | formateTax()}}</div>
        </a-form-item>
        <a-form-item label="订单编号">
          <div>{{taskData.order_no}}</div>
        </a-form-item>
        <a-form-item label="创建时间" v-if="taskData.add_time">
          <div>{{taskData.add_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="接单时间" v-if="taskData.bing_time">
          <div>{{taskData.bing_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="完成任务时间" v-if="taskData.complete_time">
          <div>{{taskData.complete_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="公司付款时间" v-if="taskData.pay_time">
          <div>{{taskData.pay_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="服务商收款时间" v-if="taskData.sure_pay_time">
          <div>{{taskData.sure_pay_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="公司申请开票时间" v-if="taskData.apply_time">
          <div>{{taskData.apply_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="服务商开票时间" v-if="taskData.sure_apply_time">
          <div>{{taskData.sure_apply_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="服务商付款时间" v-if="taskData.yi_pay_time">
          <div>{{taskData.yi_pay_time | formateDate()}}</div>
        </a-form-item>
        <a-form-item label="分包商收款时间" v-if="taskData.yi_sure_time">
          <div>{{taskData.yi_sure_time | formateDate()}}</div>
        </a-form-item>
        <a-divider orientation="left">公司信息</a-divider>
        <a-form-item label="公司名称">
          <div>{{taskData.u_company_name}}</div>
        </a-form-item>
        <a-form-item label="统一信用代码">
          <div>{{taskData.u_credit_code}}</div>
        </a-form-item>
        <a-form-item label="公司法人">
          <div>{{taskData.u_real_name}}</div>
        </a-form-item>
        <a-form-item label="联系电话">
          <div>{{taskData.u_phone}}</div>
        </a-form-item>
        <a-form-item label="公司地址">
          <div>{{taskData.u_address}}</div>
        </a-form-item>
        <a-form-item label="开户银行">
          <div>{{taskData.u_bank}}</div>
        </a-form-item>
        <a-form-item label="银行账号">
          <div>{{taskData.u_bank_code}}</div>
        </a-form-item>
        <a-divider orientation="left">服务商信息</a-divider>
        <a-row>
          <a-col :span="8">
            <a-form-item label="服务商">
              <div>{{taskData.c_real_name}}</div>
            </a-form-item>
            <a-form-item label="服务商主体">
              <div>{{taskData.c_company_name}}</div>
            </a-form-item>
            <a-form-item label="联系电话">
              <div>{{taskData.c_phone}}</div>
            </a-form-item>
            <a-form-item label="公司地址">
              <div>{{taskData.c_address}}</div>
            </a-form-item>
            <a-form-item label="开户银行">
              <div>{{taskData.c_bank}}</div>
            </a-form-item>
            <a-form-item label="银行账号">
              <div>{{taskData.c_bank_code}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider orientation="left">附件</a-divider>
        <a-form-item>
          <div>
            <div class="fj-item" v-for="(item, index) in taskData.fj" :key="index">
              <a :href="item.url" target="_blank">
                <img :src="item.url" v-if="item.type == 'img'" />
                <a-icon class="icon" v-else type="file" theme="filled" />
              </a>
            </div>
          </div>
        </a-form-item>
        <a-divider orientation="left">项目成果</a-divider>
        <a-form-item>
          <a-upload
            name="photo"
            class="upload-wrapper"
            :show-upload-list="false"
            action="/common/upload"
            @change="handleCgChange">
            <img v-if="cg" :src="cg" alt="项目成果" />
            <div class="empty" v-else>
              <a-icon class="icon" :type="loading ? 'loading' : 'picture'" />
              <div class="upload-text">点击上传图片</div>
              <div class="upload-text-tip">支持jpg/png格式，不超过2MB</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { taskDetail } from '@/utils/api'
export default {
  name: 'BingTaskDetail',
  data() {
    return {
      labelCol: { lg: { span: 10 }, sm: { span: 10 }, xs: { span: 10 } },
      wrapperCol: { lg: { span: 14 }, sm: { span: 14 }, xs: { span: 14 } },
      form: this.$form.createForm(this),
      taskData: {},
      cg: '',
      loading: false
    }
  },
  filters: {
    formateMoney(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val.toFixed(2)
      }
      return '0.00'
    },
    formateDate(val, noDetail) {
      if (val) {
        const date = new Date(parseInt(val) * 1000)
        const y = date.getFullYear()
        const m = date.getMonth() + 1
        const d = date.getDate()
        const h = date.getHours()
        const i = date.getMinutes()
        const s = date.getSeconds()
        return y + '-'
              + (m < 10 ? '0' + m : m) + '-'
              + (d < 10 ? '0' + d : d) +
              (noDetail ? '' : (' '
              + (h < 10 ? '0' + h : h) + ':'
              + (i < 10 ? '0' + i : i) + ':'
              + (s < 10 ? '0' + s : s)
              ))
      }
      return ''
    },
    formateTax(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val * 100 + '%'
      }
      return ''
    },
    formateStatus(val) {
      let str = ''
      switch (val) {
        case 0:
          str = '已创建项目'
          break;
        case 1:
          str = '已接项目'
          break;
        case 2:
          str = '已完成项目'
          break;
        case 3:
          str = '公司已支付'
          break;
        case 4:
          str = '服务商已收款'
          break;
        case 5:
          str = '公司已申请开票'
          break;
        case 6:
          str = '服务商已开票'
          break;
        case 7:
          str = '服务商已付款'
          break;
        case 8:
          str = '分包商已收款'
          break;
      }
      return str
    }
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = '订单详情'
    this.getTaskData()
  },
  methods: {
    getTaskData() {
      const id = this.$route.query.id || ''
      taskDetail({
        id
      }).then((res) => {
        const data = res.info
        const arr = []
        data.fj = data.fj ? data.fj.split(',') : []
        for (let index = 0; index < data.fj.length; index++) {
          const item = data.fj[index];
          let type = 'img'
          if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item)) {    
            type = 'file' 
          }   
          arr.push({
            url: item,
            type
          })
        }
        data.fj = arr
        this.taskData = data
      })
    },
    handleCgChange(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        this.cg = res.file.response.info
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 16px 16px;
  .ant-form-item {
    margin-bottom: 5px;
  }
  
}
@media (max-width: 575px) {
  .wrapper {
    /deep/.ant-form-item {
      display: flex;
      .ant-form-item-label {
        width: auto;
        line-height: 39.9999px;
        label {
          padding-right: 10px;
          color: #999;
          &::after {
            content: ':';
            position: absolute;
            top: 0;
            right: 1px;
          }
        }
      }
      .ant-form-item-control-wrapper {
        flex: 1;
      }
    }
  }
}

.fj-item {
  width: 120px;
  height: 120px;
  border: 1px solid #d8d8d8;
  padding: 5px;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;
  margin: 0 8px 10px 0;
  text-align: center;
  line-height: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .icon {
    font-size: 90px;
    margin-top: 5px;
  }
}

.upload-wrapper-item,
.upload-wrapper /deep/ > .ant-upload {
  width: 100%;
  height: 275px;
  border: 2px dotted #f6f6f6;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 6px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .empty {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666;
    background-color: #fff;
    .icon {
      font-size: 40px;
    }
    .upload-text {
      font-size: 16px;
    }
    .upload-text-tip {
      color: #999;
      font-size: 12px;
    }
  }
}
</style>